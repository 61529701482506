import React, { FC } from "react";
// import { Link } from "react-router-dom";
import "../App.css";

// function App() {
//   return (
const About: FC = () => (
  <>
    <h1>^^</h1>
    <p>^^</p>
  </>
);

export default About;
